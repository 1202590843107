@tailwind base;
@tailwind components;
@tailwind utilities;

main {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main.no-scroll {
  overflow: hidden;
}

/* @font-face {
  font-family: 'Saira';
  src: url(../public/fonts/saira.ttf) format('truetype');
} */
/* @font-face {
  font-family: 'Montserrat';
  src: url(../public/fonts/montserrat.ttf) format('truetype');
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
