.selectedTab {
    background-color: #2A5D67;
    border-radius: 9px;
    display: flex;
    padding-top: 0.5rem;
    margin: 0.1rem;
    padding-bottom: 0.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.selectedTab:focus:after {
    content: none;
}

.map-container {
    height: 100%;
    width: 100%;
}

.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
    top: 90%;
}

@media screen and (min-width: 630px) {
    .swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal{
        top: 105%;
    }
}
.swiper-scrollbar-drag {
    background-color: #33535f;
}

#navbar.hide {
    visibility: hidden;
}