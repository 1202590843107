.swiper-button-disabled {
    visibility: hidden;
}

.swiper-button-next, .swiper-button-prev { 
    height: 100%;
    top: 0%;
    width: 60px;
    margin-top: 0px;
}
.swiper-button-next {
    right: 0;
    background: linear-gradient(269.25deg,#2A5D67 0.62%,rgba(20, 15, 15, 0) 99.33%);
}
.swiper-button-prev {
    left: 0;
    background: linear-gradient(88deg,#2A5D67 0.62%,rgba(15,16,20,0) 99.33%);
}

.swiper-button-next::after {
    color: #fff;
    font-size: 40px;
    margin-left: 10px;
}

.swiper-button-prev::after {
    color: #fff;
    font-size: 40px;
    margin-right: 10px;
}